<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <div
        class="fieldInput--limitDb"
        v-if="meta && meta.maxLength"
    >
      <div class="fieldInput-maxLengthDb">
        <small
            class="help-block float-right"
            v-if="value"
        >
          {{ value.length }}/{{ meta.maxLength }}
        </small>
        <small
            class="help-block float-right"
            v-else
        >
          0/{{ meta.maxLength }}
        </small>
      </div>
    </div>
    <el-input
        :autocomplete="field.meta && field.meta.autocomplete ? field.meta.autocomplete : 'off'"
        :autofocus="field.meta && field.meta.autofocus ? field.meta.autofocus : false"
        :class="{'validate-error' : checkinputRequired(field.dbName) || backend_errors[field.dbName], 'pattern-validation': field.meta && field.meta.pattern}"
        :clearable="field.meta && field.meta.clearable ? field.meta.clearable : false"
        :disabled="field.meta && field.meta.disabled ? field.meta.disabled : false"
        :maxlength="field.meta && field.meta.maxLength ? field.meta.maxLength : ''"
        :minlength="field.meta && field.meta.minLength ? field.meta.minLength : ''"
        :name="field.dbName"
        :pattern="field.meta && field.meta.pattern ? field.meta.pattern : ''"
        :placeholder="field.meta && field.meta.placeholder ? field.meta.placeholder : ''"
        :prefix-icon="field.meta && field.meta.prefixIcon ? field.meta.prefixIcon : ''"
        :readonly="field.meta && field.meta.readonly ? field.meta.readonly : false"
        :resize="field.meta && field.meta.resize ? field.meta.resize : ''"
        :show-password="field.meta && field.meta.showPassword ? field.meta.showPassword : false"
        :show-word-limit="!!(field.meta && field.meta.maxLength)"
        :size="field.meta && field.meta.size ? field.meta.size : ''"
        :step="field.meta && field.meta.step ? field.meta.step : ''"
        :suffix-icon="field.meta && field.meta.suffixIcon ? field.meta.suffixIcon : ''"
        :value="value"
        v-model="value"
    >
    </el-input>
    <small
        class="help-block"
        v-if="backend_errors[field.dbName]"
    >
      <span>{{ $t(backend_errors[field.dbName][0]) }}</span>
    </small>
    <small
        class="help-block"
        v-if="!valid"
    >
      <span>{{ $t('system.field_must_be_valid') }}</span>
    </small>
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  data: () => ({
    valid: true,
  }),
  mixins: [abstractForm],
  name: 'CreateOrUpdate',
  components: {BaseFormItem},
  watch: {
    value(newVal, oldVal) {
      if (this.field.meta && this.field.meta.pattern) {
        const pattern = new RegExp(this.field.meta.pattern, 'igm');
        this.valid = pattern.test(newVal);
      } else if (this.field.validation.includes('numeric') && !/^[0-9.,]*$/.test(newVal)) {
        this.value = oldVal;
      }
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.fieldInput--limitDb {
  position: relative;
}

.fieldInput-maxLengthDb {
  position: absolute;
  top: -40px;
  right: 5px;
}
</style>
